var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"bookPage"},[_c('ShellHeader',{attrs:{"live":_vm.isLiveYoutube}}),_c('main',{staticClass:"bookPage__main"},[_c('div',[_c('div',{attrs:{"data-scroll":""}},[(!_vm.isLiveYoutube)?_c('ContentGrid',{attrs:{"items":_vm.items}}):_c('div',{staticClass:"bookPage__live",class:{
            'bookPage__live--fullscreen': _vm.isFullscreen
          }},[_c('div',{staticStyle:{"--aspect-ratio":"calc(16/9)"}},[_c('youtube',{ref:"youtube",staticStyle:{"cursor":"default !important"},attrs:{"video-id":_vm.youtube_parser(_vm.liveLink),"player-vars":{
                autoplay: 1,
                controls: 0,
                modestbranding: 1,
                cc_load_policy: 0,
                rel: 0,
                showinfo: 0
              }}})],1)]),_c('PageFooter')],1)]),_c('transition',{attrs:{"name":"fade","appear":""}},[(_vm.isPopinOpen)?_c('ContentPopin'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }