<template>
  <header
    v-show="showHeader"
    :class="{
      'shellHeader--dark': theme === 'dark',
      'shellHeader--light': theme === 'light',
      'shellHeader--book': $route.name === 'Book',
      'shellHeader--bookLive': live && $route.name === 'Book'
    }"
    class="shellHeader"
  >
    <a href="/" class="shellHeader__logo" @click.prevent="scrollToTop">
      <h1>Saint Laurent</h1>
      <LogoSvg />
    </a>
    <button
      v-show="$route.name !== 'Index' && $route.name !== 'NotFound'"
      @click="toggleMenu"
      :class="{
        'shellHeader__menuButton--close': isMenuOpen || isPopinOpen,
        'shellHeader__menuButton--popin': !isPopinOpen
      }"
      class="shellHeader__menuButton"
    >
      <span class="shellHeader__menuButtonIcon">
        <span></span>
      </span>
    </button>
  </header>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import LogoSvg from '@/components/svg/logo.svg'

export default {
  name: 'ShellHeader',
  components: {
    LogoSvg
  },
  props: {
    live: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    ...mapState({
      isLiveYoutube: state =>
        state.configuration.live ? JSON.parse(state.configuration.live) : 0,
      theme: state => state.theme,
      isMenuOpen: state => state.isMenuOpen,
      isPopinOpen: state => state.isPopinOpen,
      showHeader: state => state.showHeader
    })
  },
  methods: {
    ...mapMutations({
      setMenuOpen: 'setMenuOpen',
      setPopinOpen: 'setPopinOpen'
    }),
    toggleMenu() {
      if (this.isPopinOpen) this.setPopinOpen(!this.isPopinOpen)
      else this.setMenuOpen(!this.isMenuOpen)
    },
    scrollToTop() {
      this.$bus.$emit('scrollToTop')
    }
  }
}
</script>

<style lang="scss">
.shellHeader {
  $self: &;
  display: flex;
  justify-content: space-between;
  padding: 17px;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 4;

  @media screen and (min-width: 768px) {
    pointer-events: none;
    height: 80px;
  }

  &__logo,
  &__menuButton {
    pointer-events: auto;
  }

  // @media screen and (min-width: 768px) {
  //   align-items: flex-start;
  //   flex-direction: column;
  // }

  &--dark {
    color: var(--white);
    background-color: var(--black);

    @media screen and (min-width: 768px) {
      background-color: transparent;
    }
  }

  &--dark &__logo {
    color: white;
  }

  &--light {
    $self: &;
    color: var(--black);
    background-color: var(--white);

    @media screen and (min-width: 768px) {
      background-color: transparent;
    }
  }

  &--book {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 768px) {
      color: var(--white);

      &Live {
        color: var(--black);
      }
    }
  }

  &__logo {
    display: block;

    svg {
      display: inherit;
      @media screen and (max-width: 768px) {
        height: 16px;
        width: 162px;
      }
    }

    h1 {
      height: 0;
      margin: 0;
      text-indent: -9999px;
      width: 0;
    }
  }

  &__menuButton {
    $button: &;
    background-color: transparent;
    border: unset;
    color: inherit;
    height: 13px;
    padding: 0;
    position: relative;

    @media screen and (min-width: 768px) {
      &--popin:not(&--close) {
        display: none;
      }
      //   margin-top: 28px;
      //   transition: opacity 16ms steps(1) 500ms;
      //   opacity: 1;
    }

    // &Label {
    //   display: none;
    //   text-transform: uppercase;
    //   font-size: 10px;
    //   line-height: 12px;

    //   @media screen and (min-width: 768px) {
    //     display: block;
    //   }
    // }

    &Icon {
      // @media screen and (min-width: 768px) {
      //   display: none;
      // }

      &::before,
      &::after,
      span {
        background-color: currentColor;
        display: block;
        height: 1px;
        width: 19px;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
      }

      &::before {
        transform: translateY(-7px);
      }

      &::after {
        transform: translateY(5px);
      }
    }

    &--close {
      @media screen and (max-width: 767px) {
        transform: translateY(-1px);
      }

      // @media screen and (min-width: 768px) {
      //   transition-delay: 0s;
      //   opacity: 0;
      //   pointer-events: none;
      // }

      #{$button}Icon {
        &::before {
          transform: translateY(0) rotateZ(-45deg);
        }

        &::after {
          transform: translateY(0) rotateZ(45deg);
        }

        span {
          visibility: hidden;
        }
      }
    }
  }
}
</style>
