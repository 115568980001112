<template>
  <span class="spinner" />
</template>

<script>
export default {}
</script>

<style lang="scss">
.spinner,
.spinner:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
}

.spinner {
  display: block;
  font-size: 13px;
  position: relative;
  text-indent: -9999em;
  border: 0.1em solid rgba(255, 255, 255, 0.2);
  border-left: 0.1em solid #000000;
  will-change: transform;
  animation: load8 1.1s infinite linear;

  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
}

@keyframes load8 {
  to {
    transform: rotate(360deg);
  }
}
</style>
