<template>
  <div id="app" :class="theme">
    <ShellMenu v-if="hasMenuVisible" />
    <router-view />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import ShellMenu from '@/components/shell/menu'

export default {
  components: {
    ShellMenu
  },
  data() {
    return {
      live: true
    }
  },
  computed: {
    ...mapState({
      isLiveYoutube: state => {
        const isLive = state.configuration.live ? 1 : 0
        state.configuration.live ? isLive : 0
      },

      hasMenuVisible: state =>
        state.configuration.menuVisible
          ? JSON.parse(state.configuration.menuVisible)
          : 0,
      theme: state => state.configuration.theme || 'light'
    })
  },
  created() {
    console
    const userRole = localStorage.getItem('userRole')
    if (userRole) {
      this.setUserRole(userRole)
    }
  },
  methods: {
    ...mapMutations({
      setUserRole: 'setUserRole'
    })
  }
}
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}
</style>
